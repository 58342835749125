<template>
  <div class="platform-page">
    <div class="box-title">
      <div class="navbar-link">
        <a :class="{ active: tabsActive === 'CTRADER' }" @click="tabsActive = 'CTRADER'">
          <div class="text">CTRADER</div>
        </a>
      </div>
    </div>
    <div class="content-box">
      <div class="" v-if="tabsActive == 'CTRADER'">
        <b-row>
          <b-col cols="12" lg="4" class="my-2">
            <div class="card">
              <div class="card-header">cTrader Web</div>
              <div class="card-body">
                <div class="banner">
                  <img src="~@/assets/images/background/web.png" alt="" />
                </div>
                <div class="text">
                  <p class="mb-1">
                    cTrader Web is a fully functioning HTML5 trading platform, so you can access
                    your accounts from anywhere.
                  </p>
                  <p v-if="web" class="mb-1 mt-2">
                    While the software platform is licensed to EggsBook Unichain Solutions LLC, the
                    issuer of the products available on the platform is EggsBook Unichain Solutions
                    LLC
                  </p>
                  <span @click="web = !web">{{ !web ? 'Read More' : 'Less' }}</span>
                </div>
              </div>
              <div class="card-footer">
                <a href="https://ct.esbfx.com/" target="_blank">
                  <b-button variant="success"> Open </b-button>
                </a>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="4" class="my-2">
            <div class="card">
              <div class="card-header">cTrader Client</div>
              <div class="card-body">
                <div class="banner">
                  <img src="~@/assets/images/background/pc.png" alt="" />
                </div>
                <div class="text">
                  <p class="mb-1">
                    An institutional grade trading platform, with L2 Market Depth, advanced charting
                    and the fastest execution.
                  </p>
                  <p v-if="pc" class="mb-1 mt-2">
                    While the software platform is licensed to EggsBook Unichain Solutions LLC, the
                    issuer of the products available on the platform is EggsBook Unichain Solutions
                    LLC
                  </p>
                  <span @click="pc = !pc">{{ !pc ? 'Read More' : 'Less' }}</span>
                </div>
              </div>
              <div class="card-footer">
                <a href="https://esbfx.ctrader.com/ctrader-esbfx-setup.exe" target="_blank">
                  <b-button variant="success"> Download </b-button>
                </a>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="4" class="my-2">
            <div class="card">
              <div class="card-header">cTrader mobile app</div>
              <div class="card-body">
                <div class="banner">
                  <img src="~@/assets/images/background/mobile.png" alt="" />
                </div>
                <div class="text">
                  <p class="mb-1">
                    A specially designed mobile and tablet interfaces, accessible from any iOS or
                    Android device.
                  </p>
                  <p v-if="mobile" class="mb-1 mt-2">
                    While the software platform is licensed to EggsBook Unichain Solutions LLC, the
                    issuer of the products available on the platform is EggsBook Unichain Solutions
                    LLC
                  </p>
                  <span @click="mobile = !mobile">{{ !mobile ? 'Read More' : 'Less' }}</span>
                </div>
              </div>
              <div class="card-footer">
                <a
                  href="https://play.google.com/store/apps/details?id=com.esbfx.ct"
                  target="_blank"
                >
                  <b-button variant="success"> Android </b-button>
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabsActive: 'CTRADER',
      pc: false,
      mobile: false,
      web: false,
    };
  },
  created() {},
};
</script>

<style lang="scss">
.platform-page {
  width: 100%;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 0;
    min-height: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    .navbar-link {
      width: 100%;
      max-width: 200px;
      min-width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        a {
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        &.active {
          border-bottom: 3px solid #009751;
          background: linear-gradient(89deg, #00975146, #8ec63f75);
        }

        .text {
          color: #23222f;
          font-weight: 600;
          font-size: 20px;
          text-transform: uppercase;
        }
        .icon {
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
  }
  .content-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    padding: 15px;
    border-radius: 15px;
    .card {
      box-shadow: 0px 0px 10px 2px #00000038;
      border-radius: 10px;
      min-height: 400px;
      .card-header {
        color: #2f394e;
        font-weight: 700;
        font-size: clamp(16px, 2vw, 20px);
        text-transform: uppercase;
        text-align: center;
      }
      .card-body {
        padding: 0;

        .banner {
          text-align: center;
          background-image: url(~@/assets/images/background/overlay.png);
          padding: 15px 0;
          border-radius: 0;
          width: 100%;
        }
        .text {
          padding: 10px 1.25rem;
          color: #2f394e;
          font-weight: 600;
          min-height: 175px;
          span {
            color: #009751;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .card-footer {
        text-align: center;
        min-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #2f394e;
          font-weight: 600;
          button {
            border-radius: 20px;
            padding: 5px;
            min-width: 120px;
          }
        }
      }
    }
  }
}
</style>
